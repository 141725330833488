html,
body {
  height: 100%;
}

#root {
  height: inherit;
}

.Login {
  height: 100%;
  background-image: url("../../../Images/background.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  .Modal {
    width: 100%;
    max-width: 490px;
    margin: 0 auto;
    height: auto;
    background: #f2f1f2;
    box-shadow: 3px 3px 20px #00000065;
    border-radius: 5px;
    opacity: 1;
    padding: 30px 10px;
    box-sizing: border-box;
    .Logo {
      width: 220px;
      height: 110px;
      margin: 0 auto 30px;
    }
    input,
    .input-error,
    button,
    p,
    h3 {
      max-width: 320px;
      width: 100%;
      display: block;
      font-size: 15px;
      box-sizing: border-box;
      text-align: center;
    }
    input {
      color: #434b60;
      font-size: 15px;
      height: 35px;
      border: 1px solid #b6b7c2;
      border-radius: 2px;
      margin: 15px auto 0;
      padding: 5px 10px;
      text-align: left;
      &::placeholder {
        opacity: 0.5;
      }
      &.errors {
        border: 1px solid #c64343;
        &::placeholder {
          color: #c64343;
          opacity: 1;
        }
      }
    }
    .input-error {
      color: #c64343;
      margin: 10px auto 0;
      font-size: 13px;
    }
    button {
      background-color: #1b9ddb;
      color: #ffffff;
      font-weight: 600;
      border-radius: 2px;
      padding: 10px 0;
      margin: 30px auto 20px;
      align-items: center;
      display: flex;
      justify-content: center;
      &:focus {
        background-color: #1c84b5;
      }
      &:active {
        background-color: #106494;
        color: #999fa9;
      }
      &:disabled {
        background-color: #1b9ddb;
        opacity: 0.4;
      }
    }
    h3 {
      margin: 0 auto;
      color: #434b60;
      font-size: 16px;
      font-weight: 600;
    }
    p {
      margin: 20px auto 0;
      color: #434b60;
      text-align: left;
      font-size: 15px;
      &.text-center {
        text-align: center;
      }
    }
    a {
      font-size: 15px;
      font-weight: 600;
      text-align: center;
      letter-spacing: 0;
      color: #1b9ddb;
      text-transform: capitalize;
      text-decoration: none;
      display: block;
    }
    .MuiSvgIcon-root {
      vertical-align: middle;
    }
    .emailSent {
      background-image: url("../../../Images/Icon-emailSent.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: 65px;
      height: 65px;
      margin: 0 auto 20px;
    }
  }
}
